<template>
    <div class="paySuccess">
        <div class="centerWidth flex flexColumn alignCenter">
            <div style="width: 74px;height: 74px;background: #10c45c;border-radius:50%;margin-top:42px;" class="flex alignCenter spacearound">
                <i class="el-icon-check" style="font-size:60px;color:#fff"></i>
            </div>
            <div class="ordermsg c33 fontW" style="font-size:16px;margin-top:30px;">
                订单支付成功！
            </div>
            <div class="btns">
                <button class="backhome" @click="goother({path:'/'})">返回首页</button>
                <button class="readorder" @click="goother({path:'/personal/orderlist'})">查看订单</button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'paySuccess'
} 
</script>
<style lang="scss" scoped>
.paySuccess{

}
.btns{
    margin-top: 65px;
    margin-bottom: 155px;
    button{
        width: 105px;
        height: 33px;
        opacity: 1;
        border-radius: 2px;
        font-size: 15px;
        cursor: pointer;
    }
    .readorder{
        background: #47d7e3;
        color: #fff;
    }
    .backhome{
        border:1px solid #47D7E3;
        color: #47D7E3;
        background: #fff;
        margin-right: 12px;
    }
}
</style>